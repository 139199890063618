import React from 'react'
import { Routes } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import {
  HomePage,
  ChatExample,
  PaymentFlexibility,
  Surprise,
  SurpriseMayTheFourth,
  SurpriseAnniversary
} from './pages'

const ApplicationRoutes = () => {
  return (
    <Routes>
      <PrivateRoute path='/' element={<HomePage />} />
      <PrivateRoute path='/chat' element={<ChatExample />} />
      <PrivateRoute path='/payment-flexibility' element={<PaymentFlexibility />} />
      <PrivateRoute path='/surprise' element={<Surprise />} />
      <PrivateRoute path='/may-the-fourth' element={<SurpriseMayTheFourth />} />
      <PrivateRoute path='/anniversary' element={<SurpriseAnniversary />} />
    </Routes>
  )
}

export default ApplicationRoutes
