import * as actions from './slice'

export const userWasInitiated = {
  eventName: 'User Was Initiated',
  onSuccess: [
    {
      action: actions.setUserAttributes,
      redux: true
    }
  ]
}
