import React from 'react'
import SceneObject from '../../SceneObject'
import Box from './Box'
import TBox from './TBox'
import LBox from './LBox'
import Enemy from './Enemy'
import Coin from './Coin'
import Writings from './PacManWritings'

const visibleRange = [20000, 28500]

const PacmanGroundLayer = React.memo(props => {
  if (!(visibleRange[0] <= props.scroll && props.scroll < visibleRange[1])) { return <div /> }
  return (
    <SceneObject {...props} name='Pacman Ground Layer'>
      <SceneObject
        name='PACMANGROUND'
        color='#2e3fe6'
        transform={{ position: { x: 0, y: 50 }, scale: { x: 5100, y: 50 } }}
      />
      <Box color='#2e3fe6' transform={{ position: { x: 1600, y: 200 } }} />
      <Enemy
        transform={{ position: { x: 2100, y: 100 } }}
        type='2'
        canChange={props.scroll > 24900}
        canEat={props.scroll > 25100}
      />
      <Enemy
        transform={{ position: { x: 2350, y: 100 } }}
        type='4'
        canChange={props.scroll > 24900}
        canEat={props.scroll > 25300}
      />
      <Enemy
        transform={{ position: { x: 3200, y: 100 } }}
        type='3'
        canChange={props.scroll > 24900}
        canEat={props.scroll > 26100}
      />
      <Coin
        transform={{ position: { x: 500, y: 200 } }}
        type='1'
        canEat={props.scroll > 23500}
      />
      <Coin
        transform={{ position: { x: 700, y: 200 } }}
        type='1'
        canEat={props.scroll > 23700}
      />
      <Coin
        transform={{ position: { x: 900, y: 200 } }}
        type='1'
        canEat={props.scroll > 23900}
      />
      <Coin
        transform={{ position: { x: 1100, y: 200 } }}
        type='1'
        canEat={props.scroll > 24100}
      />
      <Coin
        transform={{ position: { x: 1300, y: 200 } }}
        type='1'
        canEat={props.scroll > 24300}
      />
      <Coin
        transform={{ position: { x: 1900, y: 400 } }}
        type='2'
        canEat={props.scroll > 24900}
      />

      <Coin
        transform={{ position: { x: 4000, y: 200 } }}
        type='1'
        canEat={props.scroll > 26920}
      />
      <Coin
        transform={{ position: { x: 4200, y: 200 } }}
        type='1'
        canEat={props.scroll > 27120}
      />
      <Coin
        transform={{ position: { x: 4400, y: 200 } }}
        type='1'
        canEat={props.scroll > 27320}
      />
      <Coin
        transform={{ position: { x: 4600, y: 200 } }}
        type='1'
        canEat={props.scroll > 27520}
      />
      <Coin
        transform={{ position: { x: 4800, y: 200 } }}
        type='1'
        canEat={props.scroll > 24300}
      />
      <Coin
        transform={{ position: { x: 1900, y: 400 } }}
        type='2'
        canEat={props.scroll > 24900}
      />
      <Writings />
    </SceneObject>
  )
})

export default PacmanGroundLayer
