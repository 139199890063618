import name from './name'

const selectSliceData = state => state[name]

const selectFirstName = state => selectSliceData(state).given_name
const selectEmail = state => selectSliceData(state).email

export {
  selectFirstName,
  selectEmail
}
