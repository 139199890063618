import { makeStyles } from '@material-ui/core/styles'

import backgroundImage from './assets/images/background.png'

export const useStyles = makeStyles(theme => ({
  root: {
    background: '#000',
    backgroundImage: `url(${backgroundImage})`,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    fontFamily: 'Chakra Petch',
    maxWidth: 500,
    margin: '0 auto'
  },
  challenge: {
    marginBottom: 30
  },
  label: {
    textTransform: 'uppercase',
    color: '#02D7F2',
    fontSize: '.9em',
    letterSpacing: '.2em',
    marginBottom: 0
  },
  lightsaber: {
    display: 'flex',
    alignItems: 'center'
  },
  hilt: {
    width: 80,
    height: 21,
    position: 'relative'
  },
  light: {
    flex: 1
  },
  progressLight: {
    height: 5,
    marginLeft: -13,
    borderRadius: '0px 10px 10px 0',
    transition: 'width 2s ease-in-out'
  },
  holocronProgress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  holocronProgressItem: {
    width: '2.7%',
    height: 16,
    transform: 'skew(-45deg)',
    transition: 'background-color .5s linear'
  }
}))
