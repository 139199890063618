import React, { useMemo, useEffect, useState } from 'react'
import Player from './Player/Player'
import Ground from './MainLayers/Ground'
import Environment from './MainLayers/Environment'
import Sky from './MainLayers/Sky'
import WithScroll from './WithScroll'
import Sound from 'react-sound'
import marioSong from './../../assets/musics/mario.mp3'
import starWarsSong from './../../assets/musics/starWars.mp3'
import lotrSong from './../../assets/musics/lotr.mp3'
import walleSong from './../../assets/musics/walle.mp3'
import pacmanSong from './../../assets/musics/pacman.mp3'
import happySong from './../../assets/musics/happy.mp3'
import './index.css'
import { surpriseSlice } from './../../reducers'
import { useSelector } from 'react-redux'
// import { height } from '@mui/system'

function ReactWorld ({ onScrolling, scroll, scrollDirection }) {
  const name = useSelector(surpriseSlice.selectors.selectName)
  console.log('NAMEEEEEE', name)
  const [currentSong, setCurrentSong] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const getSongByScrollPosition = scroll => {
    console.log(scroll)
    if (scroll < 7050) {
      return lotrSong
    } else if (scroll >= 7050 && scroll < 12280) {
      return starWarsSong
    } else if (scroll >= 12280 && scroll < 17650) {
      return walleSong
    } else if (scroll >= 17650 && scroll < 23100) {
      return marioSong
    } else if (scroll >= 23100 && scroll < 28160) {
      return pacmanSong
    } else if (scroll >= 28160) {
      return happySong
    }
    return ''
  }

  const SoundElement = useMemo(
    () => (
      <Sound
        url={currentSong}
        playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
        volume={10}
        loop
      />
    ),
    [currentSong, isPlaying]
  )

  useEffect(() => {
    const song = getSongByScrollPosition(scroll)
    if (song !== currentSong) setCurrentSong(song)
  }, [scroll])

  return (
    <div className='rw'>
      {SoundElement}
      <div style={{
        display: isPlaying ? 'none' : 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.9)',
        zIndex: 4
      }}
      >
        <>
        </>
      </div>
      <div style={{
        display: isPlaying ? 'none' : 'block',
        position: 'absolute',
        top: '50%',
        right: '50%',
        transform: 'translate(50%,-50%)',
        width: 350,
        height: 450,
        background: 'black',
        textAlignLast: 'center',
        zIndex: 5,
        border: '10px solid #FFEB3B'
      }}
      >
        <h1>
          <div
            style={{
              marginTop: '1%',
              color: '#FFEB3B',
              fontSize: '20px'
            }}
          >
            Welcome
          </div>
          <div style={{
            marginTop: '2%',
            fontSize: '25px'
          }}
          >
            {name}
          </div>
        </h1>
        <p style={{
          fontSize: '30px',
          marginTop: '14%',
          color: '#FFEB3B',
          letterSpacing: '5px'
        }}
        >
        Are  <br />you <br /> Ready?
        </p>
        <button className='playButton' onClick={() => setIsPlaying(!isPlaying)}>
          {!isPlaying ? 'Play' : 'Stop'}
        </button>
      </div>
      <Player
        onScrolling={onScrolling}
        scroll={scroll}
        scrollDirection={scrollDirection}
      />
      <Sky name='Sky' scroll={scroll} />
      <Environment name='Environment' scroll={scroll} />
      <Ground name='Ground' scroll={scroll} />
    </div>
  )
}

const wrappedApp = () =>
  <WithScroll className='reactWorld'>
    <ReactWorld />
  </WithScroll>

export default React.memo(wrappedApp)
