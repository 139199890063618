import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const surprise = createSlice({
  name,
  initialState: {
    name: '',
    since: 0,
    hours: 0,
    commits: 0,
    messages: 0,
    surprise: null,
    user: null,
    challenges: []
  },
  reducers: {
    setName: (state, { payload }) => {
      state.name = payload.name
    },
    setSince: (state, { payload }) => {
      state.since = payload.since
    },
    setHours: (state, { payload }) => {
      state.hours = payload.hours
    },
    setCommits: (state, { payload }) => {
      state.commits = payload.commits
    },
    setMessages: (state, { payload }) => {
      state.messages = payload.messages
    },
    setSurprise: (state, { payload }) => {
      state.surprise = payload.surprise
    },
    setUser: (state, { payload }) => {
      state.user = payload.user
      if (payload.users) {
        state.users = payload.users
      }
    },
    setUsers: (state, { payload }) => {
      state.users = payload
    },
    startGame: (state) => {
      state.statusGame = 'playing'
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setName,
  setSince,
  setHours,
  setCommits,
  setMessages,
  setSurprise,
  setUser,
  setUsers,
  startGame
} = surprise.actions

// Reducer
export default surprise.reducer
