import * as actions from './slice'

export const surpriseWasInitiated = {
  eventName: 'Surprise Was Initiated',
  onSuccess: [
    {
      action: actions.setName,
      redux: true
    },
    {
      action: actions.setSince,
      redux: true
    },
    {
      action: actions.setHours,
      redux: true
    },
    {
      action: actions.setCommits,
      redux: true
    },
    {
      action: actions.setMessages,
      redux: true
    },
    {
      action: actions.setSurprise,
      redux: true
    }
  ]
}

export const surpriseMayTheFourthAddAnswer = {
  eventName: 'May The Fourth Surprise Add Answer',
  onSuccess: [
    {
      action: actions.setUsers,
      redux: true
    }
  ]
}

export const surpriseMayTheFourthStart = {
  eventName: 'May The Fourth Surprise Start',
  onSuccess: [
    {
      action: actions.startGame,
      redux: true
    },
    {
      action: actions.setUsers,
      redux: true
    }
  ]
}

export const surpriseMayTheFourthWasInitiated = {
  eventName: 'May The Fourth Surprise Was Initiated',
  onSuccess: [
    {
      action: actions.setUsers,
      redux: true
    }
  ]
}

export const surpriseMayTheFourthWasUpdated = {
  eventName: 'May The Fourth Surprise Was Initiated',
  subscribeOnInit: true,
  onSuccess: {
    action: actions.setUsers,
    redux: true
  }
}
