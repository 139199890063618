import { configureStore } from '@reduxjs/toolkit'
import chatExample from './chat-example'
import benefits from './benefits'
import user from './user'
import surprise from './surprise'

export default configureStore({
  reducer: {
    [chatExample.name]: chatExample.reducer,
    [benefits.name]: benefits.reducer,
    [user.name]: user.reducer,
    [surprise.name]: surprise.reducer
  }
})
