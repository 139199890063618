import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const benefits = createSlice({
  name,
  initialState: {
    hourlyRate: false,
    paidLeaveDays: 0,
    paidLeaveDaySaved: false
  },
  reducers: {
    setHourlyRate: (state, { payload }) => {
      state.hourlyRate = payload.hourlyRate
    },
    setPayedLeaveDays: (state) => {
      state.paidLeaveDaySaved = true
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setHourlyRate,
  setPayedLeaveDays
} = benefits.actions

// Reducer
export default benefits.reducer
