import React from 'react'
import { useStyles } from './style'
import { useSelector } from 'react-redux'
import { userSlice } from './../../reducers'

const FormExample = () => {
  const classes = useStyles()
  const firstName = useSelector(userSlice.selectors.selectFirstName)
  return (
    <div className={classes.root}>
      <div>
        <h1>Welcome {firstName}</h1>
      </div>
    </div>
  )
}

export default FormExample
