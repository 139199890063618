import name from './name'

const selectSliceData = state => state[name]

const selectName = state => selectSliceData(state).name
const selectSince = state => selectSliceData(state).since
const selectHours = state => selectSliceData(state).hours
const selectCommits = state => selectSliceData(state).commits
const selectMessages = state => selectSliceData(state).messages
const selectSurprise = state => selectSliceData(state).surprise
const selectUsers = state => selectSliceData(state).users
const selectStatusGame = state => selectSliceData(state).statusGame

export {
  selectName,
  selectSince,
  selectHours,
  selectCommits,
  selectMessages,
  selectSurprise,
  selectUsers,
  selectStatusGame
}
