import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { surpriseSlice } from './../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'

export default function DiscreteSlider () {
  const challenges = useSelector(surpriseSlice.selectors.selectChallenges)
  const [password, setPassword] = useState('')
  const [addAnswer] = useEvent([
    surpriseSlice.eventBus.surpriseMayTheFourthAddAnswer
  ])
  return (
    <>
      {challenges.map(challenge => (
        <>
          <h3 key={challenge.id}>
            {challenge.id}
          </h3>
          <CircularProgress value={challenge.progress} />
        </>
      ))}
      <br />
      <TextField
        id='outlined-basic'
        label='Password'
        variant='outlined'
        onChange={event => setPassword(event.target.value)}
      />
      <Button
        onClick={() => {
          addAnswer.trigger({ answer: password })
        }}
      >
        Go
      </Button>
      <br />
    </>
  )
}
