import React from 'react'
import { CustomThemeProvider } from './theme'

import AppLoading from '../components/app-loading'
import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import { AuthProvider } from '@emerald-works/react-auth'
import { authSettings } from '../libs/amplify'
import { ChatProvider } from './chat'
import { benefitsSlice, surpriseSlice, userSlice } from '../reducers'

export default function RootContextProvider ({ children }) {
  const eventBusAuthTokenConfig = {
    generate: true,
    apiGatewayAddress: process.env.REACT_APP_API_GATEWAY_ADDRESS,
    awsRegion: process.env.REACT_APP_API_GATEWAY_REGION,
    generateTokenEndpoint: '/generate-token'
  }

  const handleAuthRedirection = ({ Auth, federatedSignInParams }) => {
    Auth.federatedSignIn({
      ...federatedSignInParams,
      provider: 'devsquad'
    })
  }

  return (
    <CustomThemeProvider>
      <AuthProvider
        amplifyAuthConfig={authSettings()}
        onAuthNeededRedirection={handleAuthRedirection}
        allowGuestUsers
        eventBusAuthTokenConfig={eventBusAuthTokenConfig}
      >
        <EventBusProvider
          eventBusURL={process.env.REACT_APP_EVENT_BUS_URL}
          connectionParams={{
            tenantKey: 'booking'
          }}
          useAuthProvider
          namespace='app-name'
          waitForConnection
          LoadingComponent={<AppLoading />}
          initialisers={[
            benefitsSlice.eventBus.hourlyRateWasRequested,
            surpriseSlice.eventBus.surpriseWasInitiated,
            surpriseSlice.eventBus.surpriseMayTheFourthWasInitiated,
            userSlice.eventBus.userWasInitiated
          ]}
          onConnectionChange={(status) => console.log('Connection onConnectionChange hook with status:', status)}
          onOpen={() => {}}
          onClose={() => console.log('Connection onClose hook')}
        >

          <ChatProvider>
            {children}
          </ChatProvider>

        </EventBusProvider>
      </AuthProvider>
    </CustomThemeProvider>
  )
}
