import React from 'react'
import SceneObject from '../../SceneObject'
import { DateTime, Interval } from 'luxon'
import { useSelector } from 'react-redux'
import { surpriseSlice } from './../../../../reducers'

const Writings = React.memo(props => {
  const since = useSelector(surpriseSlice.selectors.selectSince)
  const sinceDateTime = DateTime.fromISO(since)
  const now = DateTime.now()
  const interval = Interval.fromDateTimes(sinceDateTime, now)
  const date = {
    months: Math.round(interval.length('months')),
    days: Math.round(interval.length('days')),
    hours: Math.round(interval.length('hours')),
    seconds: Math.round(interval.length('seconds'))
  }

  return (
    <div>
      <SceneObject
        transform={{ position: { x: 600, y: 600 }, scale: { x: 4000, y: 100 } }}
      >
        <h2 style={{ color: 'Green' }}> Your journey over Middle Earth on devsquad started {date.months} months ago </h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4600, y: 600 }, scale: { x: 800, y: 100 } }}
      >
        <h2 style={{ color: 'Green' }}>This means {date.days} days</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4950, y: 490 }, scale: { x: 800, y: 100 } }}
      >
        <h2 style={{ color: 'Green' }}>And {date.hours} hours</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 5150, y: 320 }, scale: { x: 800, y: 100 } }}
      >
        <h2 style={{ color: 'Green' }}>Precisely {date.seconds} seconds</h2>
      </SceneObject>
    </div>

  )
})

export default Writings
