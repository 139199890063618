import name from './name'

const selectSliceData = state => state[name]

const selectHourlyRate = state => selectSliceData(state).hourlyRate
const selectPaidLeaveDaysSaved = state => selectSliceData(state).paidLeaveDaySaved

export {
  selectHourlyRate,
  selectPaidLeaveDaysSaved
}
