import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Routes from './routes'
import { useStyles } from './style'
import Logger from './utils/logger'
import RootContextProvider from './contexts/root'

function App () {
  const classes = useStyles()
  return (
    <RootContextProvider>
      <div className={classes.root}>
        {Logger('Connected Successfully!')}
        <CssBaseline />
        <Routes />
      </div>
    </RootContextProvider>
  )
}

export default App
