import React, { useState } from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

export default function Logger (message) {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={isOpen}
      autoHideDuration={2000}
      onClose={() => setIsOpen(false)}
    >
      <Alert elevation={6} onClose={() => setIsOpen(false)} variant='filled' severity='success'>
        Successfully connected!
      </Alert>
    </Snackbar>
  )
}
