import React from 'react'
import SceneObject from '../../SceneObject'

const Writings = React.memo(props => {
  return (
    <div>
      <SceneObject
        transform={{ position: { x: 280, y: 620 }, scale: { x: 1000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>And as a way to</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 315, y: 570 }, scale: { x: 800, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>recognize you</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 1510, y: 620 }, scale: { x: 1000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>For all this </h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 1450, y: 570 }, scale: { x: 1000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>delivery hunger</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 3000, y: 600 }, scale: { x: 2000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>learning and knowledge</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4700, y: 620 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>We want to say</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4880, y: 570 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>something:!</h2>
      </SceneObject>
    </div>

  )
})

export default Writings
