import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import GenerateBackgroundColor from './GenerateBackgroundColor'
import { surpriseSlice } from './../../reducers'

const WithScroll = ({ WrappedComponent, ...props }) => {
  const surprise = useSelector(surpriseSlice.selectors.selectSurprise)

  const [state, setState] = useState({
    scroll: 0,
    onScrolling: false,
    scrollDirection: 1,
    scrollDelta: 0
  })
  let oldScroll = 0
  const startScroll = 0
  const maxScroll = 29000
  const scrollIncrement = 10
  const autoScroll = false

  useEffect(() => {
    setState({
      ...state, scroll: startScroll
    })
    document.location = '#'
    hackTheScroll(state.scroll)
    window.addEventListener('scroll', animateWithScroll)
    autoAnimate()
    return () => {
      window.removeEventListener('scroll', animateWithScroll)
    }
  }, [])

  const autoAnimate = () => {
    requestAnimationFrame(autoAnimate)
    if (!autoScroll) return
    const newState = { ...state }
    newState.onScrolling = true
    newState.scrollDirection = 1
    if (newState.scroll < maxScroll) {
      newState.scroll += scrollIncrement
    }
    setState(newState)

    hackTheScroll(newState.scroll)
  }

  const animateWithScroll = event => {
    if (autoScroll) return
    const newState = { ...state }
    newState.onScrolling = true
    newState.scrollDirection = window.scrollY - oldScroll > 0 ? 1 : 0
    newState.scroll =
      window.scrollY < maxScroll ? window.scrollY : maxScroll
    oldScroll = window.scrollY
    setState(newState)
  }

  const hackTheScroll = (val, event = null) => {
    val = Math.floor(val)

    const el = event || window

    el.scroll(val, val)
    el.scrollBy(val, val)
    el.scrollTo(val, val)
  }
  if (!surprise) {
    return <h1>Not Found</h1>
  }
  return (
    <div
      style={{
        height: `${window.innerHeight + maxScroll}px`,
        backgroundColor: GenerateBackgroundColor(state.scroll),
        transition: 'background-color 2s',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      {React.cloneElement(props.children, { ...props, ...state })}
      <div style={{ position: 'fixed', width: '100%' }}>
        <div
          style={{
            width: `${(state.scroll / maxScroll) * 100}%`,
            height: 25,
            backgroundColor: 'Tr'
          }}
        />
        <div>
          {state.scroll > 0 ? (
            <h1 style={{ marginLeft: 40, cursor: 'pointer' }} />
          ) : (
            <></>)}
        </div>
      </div>
    </div>
  )
}
export default WithScroll
