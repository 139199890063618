import React from 'react'
import SceneObject from '../../SceneObject'

const Writings = React.memo(props => {
  return (
    <div>
      <SceneObject
        transform={{ position: { x: 150, y: 600 }, scale: { x: 2500, y: 100 } }}
      >
        <h2 style={{ color: 'Black' }}> Committed and focused you have worked...</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 2800, y: 600 }, scale: { x: 900, y: 100 } }}
      >
        <h2 style={{ color: 'Black' }}> Dressed our shirt </h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 3000, y: 530 }, scale: { x: 600, y: 100 } }}
      >
        <h2 style={{ color: 'Black' }}>you have</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4080, y: 600 }, scale: { x: 1000, y: 100 } }}
      >
        <h2 style={{ color: 'Black' }}> And the dark side </h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4120, y: 530 }, scale: { x: 600, y: 100 } }}
      >
        <h2 style={{ color: 'Black' }}>turned not you</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4350, y: 450 }, scale: { x: 600, y: 100 } }}
      >
        <h2 style={{ color: 'Black' }}>have</h2>
      </SceneObject>
    </div>

  )
})

export default Writings
