// character images
import marioMini01 from '../../../assets/images/marioMini/mario_mini_01.png'
import marioMini02 from '../../../assets/images/marioMini/mario_mini_02.png'
import marioMini03 from '../../../assets/images/marioMini/mario_mini_03.png'
import marioMini11 from '../../../assets/images/marioMini/mario_mini_11.png'
import marioMini12 from '../../../assets/images/marioMini/mario_mini_12.png'
import marioMini13 from '../../../assets/images/marioMini/mario_mini_13.png'

import mario01 from '../../../assets/images/mario/mario_01.png'
import mario02 from '../../../assets/images/mario/mario_02.png'
import mario03 from '../../../assets/images/mario/mario_03.png'
import mario11 from '../../../assets/images/mario/mario_11.png'
import mario12 from '../../../assets/images/mario/mario_12.png'
import mario13 from '../../../assets/images/mario/mario_13.png'

import gandalf01 from '../../../assets/images/gandalf/gandalf_01.png'
import gandalf02 from '../../../assets/images/gandalf/gandalf_02.png'
import gandalf03 from '../../../assets/images/gandalf/gandalf_03.png'
import gandalf11 from '../../../assets/images/gandalf/gandalf_11.png'
import gandalf12 from '../../../assets/images/gandalf/gandalf_12.png'
import gandalf13 from '../../../assets/images/gandalf/gandalf_13.png'

import pacman01 from '../../../assets/images/pacman/pacman_01.png'
import pacman02 from '../../../assets/images/pacman/pacman_02.png'
import pacman11 from '../../../assets/images/pacman/pacman_11.png'
import pacman12 from '../../../assets/images/pacman/pacman_12.png'

import vader01 from '../../../assets/images/vader/vader_01.png'
import vader02 from '../../../assets/images/vader/vader_02.png'
import vader03 from '../../../assets/images/vader/vader_03.png'
import vader11 from '../../../assets/images/vader/vader_11.png'
import vader12 from '../../../assets/images/vader/vader_12.png'
import vader13 from '../../../assets/images/vader/vader_13.png'

import walle01 from '../../../assets/images/walle/walle_01.png'
import walle02 from '../../../assets/images/walle/walle_02.png'
import walle03 from '../../../assets/images/walle/walle_03.png'
import walle11 from '../../../assets/images/walle/walle_11.png'
import walle12 from '../../../assets/images/walle/walle_12.png'
import walle13 from '../../../assets/images/walle/walle_13.png'

const heroes = {
  space: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      ['', '', ''], // backward
      ['', '', ''] // forward
    ]
  },
  mario_mini: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [marioMini01, marioMini02, marioMini03], // backward
      [marioMini11, marioMini12, marioMini13] // forward
    ]
  },
  mario: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [mario01, mario02, mario03], // backward
      [mario11, mario12, mario13] // forward
    ]
  },
  gandalf: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [gandalf01, gandalf02, gandalf03], // backward
      [gandalf11, gandalf12, gandalf13] // forward
    ]
  },
  pacman: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [pacman01, pacman02, pacman02], // backward
      [pacman11, pacman12, pacman12] // forward
    ]
  },
  vader: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [vader01, vader02, vader03], // backward
      [vader11, vader12, vader13] // forward
    ]
  },
  walle: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [walle01, walle02, walle03], // backward
      [walle11, walle12, walle13] // forward
    ]
  }
}

let count = 0
let lastDirection = 1
let currentHero = 'gandalf'

const heroesShowUp = {
  '0-7050': { hero: 'gandalf' },
  '7050-12100': { hero: 'vader' },
  '12100-12600': { hero: 'space' },
  '12600-17650': { hero: 'walle' },
  '17650-19050': { hero: 'mario_mini' },
  '19050-21975': { hero: 'mario' },
  '21975-23100': { hero: 'space' },
  '23100-28150': { hero: 'pacman' },
  '28150-29050': { hero: 'space' }
}

/** search in `animations` by frame(scroll) */
const determineAnim = (scroll) => {
  try {
    let anim = null

    const currFrame = scroll

    let animRange
    Object.keys(heroesShowUp).some((k) => {
      animRange = k.split('-')

      // if it out of current range
      if (currFrame < animRange[0] || animRange[1] <= currFrame) {
        return false
      }

      anim = heroesShowUp[k]
      return true
    })

    if (anim === undefined || anim === null || anim === '') return currentHero

    return anim.hero
  } catch (e) {
    return currentHero
  }
}

/**
 *
 * @param {*} onScrolling
 * @param {*} scrollDirection
 */
const getWalkAnimation = (onScrolling, scrollDirection, scroll) => {
  if (onScrolling) {
    currentHero = determineAnim(scroll)
    if (scrollDirection === lastDirection) {
      count = count === heroes[currentHero].speed - 1 ? 0 : count + 1
    } else {
      count = 0
    }
    lastDirection = scrollDirection
  }
  return heroes[currentHero].frames[scrollDirection][
    Math.floor(count / heroes[currentHero].delimiter)
  ]
}

export default getWalkAnimation
