import * as actions from './slice'

export const hourlyRateWasRequested = {
  eventName: 'Hourly Rate Was Requested',
  onSuccess: [
    {
      action: actions.setHourlyRate,
      redux: true
    }
  ]
}

export const paidLeaveDaysHasChanged = {
  eventName: 'Paid Leave Days Has Changed',
  onSuccess: [
    {
      action: actions.setPayedLeaveDays,
      redux: true
    }
  ]
}

// export const clearMessages = {
//   eventName: 'clearMessages',
//   subscribeOnInit: true,
//   onSuccess: {
//     action: actions.setMessages,
//     redux: true
//   }
// }
