import React from 'react'
import SceneObject from '../../SceneObject'
import { useSelector } from 'react-redux'
import { surpriseSlice } from './../../../../reducers'

const EndText = React.memo(props => {
  const surpriseText = useSelector(surpriseSlice.selectors.selectSurprise)

  return (
    <div>
      <SceneObject
        name='Thanks'
        transform={{
          position: { x: 2000, y: 2250 },
          scale: { x: 900, y: 1000 },
          rotation: props.transform.rotation
        }}
      >
        <h2 style={{ color: 'White' }}>
          Surprise!
        </h2>
      </SceneObject>
      <SceneObject
        transform={{
          position: { x: 1750, y: 2250 },
          scale: { x: 1100, y: 900 }
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: surpriseText }}
          style={{ color: 'Black' }}
        />
      </SceneObject>
    </div>
  )
})
export default EndText
