import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'

import { surpriseSlice } from './../../reducers'
import { useStyles } from './style'
import holocron from './assets/images/holocron.png'
import sideLeft from './assets/images/side_left.png'
import sideRight from './assets/images/side_right.png'
import start from './assets/images/start.png'

const getHolocronProgress = (users = []) => {
  return users.reduce((total, challenge) => {
    if (challenge.progress < 1) return total

    return ++total
  }, 0)
}

const getHolocronColor = (currentPosition, totalItems, users) => {
  const progress = getHolocronProgress(users)

  if (
    progress &&
    currentPosition <= (totalItems * progress) / users.length - 1
  ) {
    return '#04FFD2'
  }

  return '#0F324D'
}

const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min)
}

export default function DiscreteSlider () {
  const crystalColors = [
    '#7AFFD0',
    '#B340FF',
    '#2388FE',
    '#0EF1FF',
    '#FFFF07',
    '#F76879',
    '#ff0ee1',
    '#ff5722',
    '#8bc34a'
  ]

  const classes = useStyles()
  const holocronProgressItems = [...Array(27).keys()]
  const usersSelector = useSelector(surpriseSlice.selectors.selectUsers)
  // const statusGame = useSelector(
  //   surpriseSlice.selectors.selectStatusGame
  // )
  const [statusGame, setStatusGame] = useState('start')
  const [animateFirstTime, setAnimateFirstTime] = useState(true)
  const [password, setPassword] = useState('')
  const [writeAnswer, setWriteAnswer] = useState(false)
  const [users, setUsers] = useState([])
  const [addAnswer, gameStart, wasUpdated] = useEvent([
    surpriseSlice.eventBus.surpriseMayTheFourthAddAnswer,
    surpriseSlice.eventBus.surpriseMayTheFourthStart,
    surpriseSlice.eventBus.surpriseMayTheFourthWasUpdated
  ])

  const mapHolocronProgress = () => {
    return holocronProgressItems.map((item, index) => {
      const background =
        item === holocronProgressItems.length - 1
          ? `linear-gradient(19deg, ${getHolocronColor(
              item,
              holocronProgressItems.length,
              users
            )} 74%, transparent 60%)`
          : getHolocronColor(item, holocronProgressItems.length, users)
      return (
        <div
          key={index}
          className={classes.holocronProgressItem}
          style={{
            background
          }}
        />
      )
    })
  }

  useEventsOnViewLoad(() => {
    wasUpdated.trigger()
  }, [wasUpdated])

  useEffect(() => {
    if (statusGame === 'playing') {
      if (animateFirstTime) {
        setTimeout(() => {
          setUsers(
            usersSelector.map(user => {
              return { ...user, progress: 0 }
            })
          )
        }, 30)
        setTimeout(() => {
          setUsers(
            usersSelector.map(user => {
              return { ...user, progress: 1 }
            })
          )
        }, 500)

        setTimeout(() => {
          setUsers(usersSelector)
          setAnimateFirstTime(false)
        }, 3000)
      } else {
        setUsers(usersSelector)
      }
    }
  }, [statusGame, usersSelector, animateFirstTime])

  const startGame = () => {
    setStatusGame('loading')
    setTimeout(() => setStatusGame('selectCrystalColor'), 500)
  }

  const selectCrystalColor = crystalColor => {
    const hiltImage = `hilt${generateRandomNumber(1, 6)}.png`
    gameStart.trigger({ hiltImage, crystalColor })
    setStatusGame('loadingCrystalColor')
    setTimeout(() => setStatusGame('playing'), 500)
  }

  return (
    <div className={classes.root}>
      {statusGame !== 'playing' &&
        (statusGame === 'start' || statusGame === 'loading') && (
          <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: -50
            }}
        >
          <div
              className={`animate__animated animate__fadeInUp ${
                statusGame !== 'start' ? 'animate__zoomOut' : null
            }`}
              style={{
              color: '#02D7F2',
              border: '1px solid #02D7F2',
              padding: '0px 10px 100px 0px'
            }}
            >
              <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center'
                }}
            >
              <div
                  style={{
                  border: 'none',
                  borderBottom: '1px solid #02D7F2',
                  width: 50,
                  height: 2,
                  marginRight: 10
                }}
                />
              <p>
                  {new Date().getDate()}.{'0' + (new Date().getMonth() + 1)}.
                  {new Date().getFullYear()}
                </p>
            </div>
              <div style={{ textAlign: 'center', marginTop: 50 }}>
              <p style={{ fontSize: '1.9em', marginBottom: 5 }}>DEVSQUAD</p>
              <p style={{ fontSize: '1.2em', margin: 0 }}>
                  Do or do not. There is no try.
                </p>
            </div>
            </div>
          {usersSelector && usersSelector.length > 0 && (
              <div
              className={`animate__animated animate__fadeInDown ${
                  statusGame !== 'start' ? 'animate__fadeOutDown' : null
                }`}
              style={{
                textAlign: 'center',
                marginTop: 50,
                cursor: 'pointer'
                }}
              onClick={startGame}
            >
              <img width='150' src={start} alt='start ' />
            </div>
          )}
        </div>
      )}

      {statusGame !== 'playing' &&
        (statusGame === 'selectCrystalColor' ||
          statusGame === 'loadingCrystalColor') && (
            <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: -50
              }}
        >
          <div
                className={`${
                animateFirstTime ? 'animate__animated' : null
              } animate__fadeInUp ${
                statusGame !== 'selectCrystalColor' ? 'animate__zoomOut' : null
            }`}
                style={{
              color: '#02D7F2',
              border: '1px solid #02D7F2',
              padding: '0px 10px 45px 0px'
            }}
              >
                <div style={{ textAlign: 'center', marginTop: 50 }}>
              <p
                    className={`${
                    animateFirstTime ? 'animate__animated' : null
                } animate__flash animate__repeat-2`}
                    style={{ fontSize: '1.9em', marginBottom: 5 }}
                  >
                  Choose your crystal
                  </p>

              <div
                    style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
                  >
                    {crystalColors.map((color, index) => (
                  <div
                        key={index}
                        style={{
                      background: color,
                      width: 50,
                      height: 50,
                      boxShadow: `0px 0px 23px ${color}`,
                      margin: '5%',
                      cursor: 'pointer'
                    }}
                        className={`${
                        animateFirstTime ? 'animate__animated' : null
                    } animate__rotateIn animate__delay-2s`}
                        onClick={() => selectCrystalColor(color)}
                      />
                ))}
                  </div>
            </div>
              </div>
        </div>
      )}

      {statusGame === 'playing' && (
        <>
          <div>
            <div
              className='animate__animated animate__slideInLeft'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 40
              }}
            >
              <img src={holocron} alt='holocron' style={{ marginRight: 13 }} />
              <div style={{ width: '100%', maxWidth: 350 }}>
                <div
                  style={{
                    color: '#5CF4FF',
                    display: 'flex',
                    alignItems: 'baseline'
                  }}
                >
                  <hr
                    style={{
                      border: 'none',
                      borderBottom: '1px solid #5CF4FF',
                      flex: 1
                    }}
                  />
                  <p style={{ fontSize: '.7em', margin: 0, marginLeft: 3 }}>
                    HOLOCRON - {getHolocronProgress(users)}/
                    {usersSelector && usersSelector.length ? usersSelector.length : 0} COMPLETED
                  </p>
                </div>
                <div className={classes.holocronProgress}>
                  {mapHolocronProgress()}
                </div>
                <div
                  style={{ marginTop: 7, borderBottom: '1px solid #5CF4FF' }}
                />
              </div>
            </div>

            {users.map(user => (
              <div key={user.id} className={classes.challenge}>
                <p className={classes.label}>
                  {user.id} {user.progress * 100 || 0}%
                </p>
                <div className={classes.lightsaber}>
                  <div className={classes.hilt}>
                    <img
                      className='animate__animated animate__slideInLeft'
                      src={`/images/${user.hiltImage}`}
                      width='100%'
                    />
                  </div>
                  <div className={classes.light}>
                    <div
                      className={classes.progressLight}
                      style={{
                        width: `${user.progress * 100}%`,
                        background: user.crystalColor,
                        boxShadow:
                          user.progress >= 1
                            ? `0px 0px 23px ${user.crystalColor || '#ff6600'}`
                            : 'none'
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div>
            {writeAnswer ? (
              <>
                <input
                  id='outlined-basic'
                  onChange={event => setPassword(event.target.value)}
                  style={{
                    textTransform: 'uppercase',
                    background: '#002b30',
                    color: '#02D7F2',
                    border: '1px solid #02D7F2',
                    textAlign: 'center',
                    padding: 20,
                    outline: 'none',
                    width: '100%'
                  }}
                />

                <div style={{ display: 'flex', height: 50, marginTop: 20 }}>
                  <img src={sideLeft} alt='sideLeft' />
                  <Button
                    style={{
                      flex: 1,
                      background: '#02D7F2',
                      color: '#010101',
                      fontSize: '1.2em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 0
                    }}
                    onClick={() => {
                      addAnswer.trigger({ answer: password })
                      setWriteAnswer(false)
                    }}
                  >
                    SUBMIT
                  </Button>
                  <img src={sideRight} alt='sideRight' />
                </div>
              </>
            ) : (
              <div
                style={{
                  textTransform: 'uppercase',
                  background: '#002b30',
                  color: '#02D7F2',
                  border: '1px solid #02D7F2',
                  textAlign: 'center',
                  padding: 20
                }}
                onClick={() => setWriteAnswer(true)}
              >
                write your answer here
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
