import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const surprise = createSlice({
  name,
  initialState: {
    name: {},
    email: {}
  },
  reducers: {
    setUserAttributes: (state, { payload }) => {
      Object.keys(payload).map(key => {
        state[key] = payload[key]
      })
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setUserAttributes
} = surprise.actions

// Reducer
export default surprise.reducer
