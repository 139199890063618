export default function GenerateBackgroundColor (scroll) {
  return scroll >= 0 && scroll < 5050
    ? '#edbd64'
    : scroll >= 7050 && scroll < 12100
      ? 'maroon'
      : scroll >= 12100 && scroll < 17650
        ? '#edbd64'
        : scroll >= 17650 && scroll < 22075
          ? '#A1ADFF'
          : scroll >= 23100 && scroll < 28150
            ? 'black'
            : scroll >= 28150 && scroll < 31000
              ? '#FFEB3B'
              : 'black'
}
