import React from 'react'
import SceneObject from '../../SceneObject'
import { useSelector } from 'react-redux'
import { surpriseSlice } from './../../../../reducers'

const Writings = React.memo(props => {
  const harvestHours = useSelector(surpriseSlice.selectors.selectHours)
  const slackMessages = useSelector(surpriseSlice.selectors.selectMessages)

  return (
    <div>
      <SceneObject
        transform={{ position: { x: 400, y: 600 }, scale: { x: 4000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>You moved through</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 550, y: 550 }, scale: { x: 1000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>the gears</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 1700, y: 600 }, scale: { x: 1000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}> Worked {harvestHours} hours</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 1870, y: 550 }, scale: { x: 800, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}> to Harvest</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 3100, y: 600 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>Posted {slackMessages}</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 3240, y: 550 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>messages in slack</h2>
      </SceneObject>
      {/* <SceneObject
        transform={{ position: { x: 4290, y: 600 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>Posted {slackMessages}</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 4160, y: 550 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>messages in slack</h2>
      </SceneObject> */}
    </div>

  )
})

export default Writings
