import * as actions from './slice'

export const getMessages = {
  eventName: 'getMessages',
  canSubscribe: false,
  onSuccess: [
    {
      action: actions.setMessages,
      redux: true
    },
    {
      action: actions.simpleAction,
      redux: true
    }
  ]
}

export const sendMessage = {
  eventName: 'sendMessage',
  subscribeOnInit: true,
  onSuccess: {
    action: actions.addMessage,
    redux: true
  }
}

export const clearMessages = {
  eventName: 'clearMessages',
  subscribeOnInit: true,
  onSuccess: {
    action: actions.setMessages,
    redux: true
  }
}
