import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { benefitsSlice } from './../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    margin: 30
  },
  margin: {
    height: theme.spacing(3)
  }
}))

const marks = [
  {
    value: 0,
    label: 'R$ 5.00'
  },
  {
    value: 1,
    label: 'R$ 4.75'
  },
  {
    value: 2,
    label: 'R$ 4.50'
  },
  {
    value: 3,
    label: 'R$ 4.25'
  }
]

function valuetext (value) {
  return `${value}°C`
}

export default function DiscreteSlider () {
  const classes = useStyles()
  const [savePaidLeaveDays] = useEvent([
    benefitsSlice.eventBus.paidLeaveDaysHasChanged
  ])
  const hourlyRate = useSelector(benefitsSlice.selectors.selectHourlyRate)
  return (
    <div className={classes.root}>
      <Typography
        id='discrete-slider-custom'
        style={{ marginBottom: 45 }}
        gutterBottom
      >
        QTD de paid leaves por mes {hourlyRate}
      </Typography>
      <Slider
        defaultValue={0}
        getAriaValueText={valuetext}
        aria-labelledby='discrete-slider-custom'
        step={null}
        valueLabelDisplay='on'
        marks={marks}
        max={3}
      />
      <Button
        onClick={() => {
          savePaidLeaveDays.trigger({ paidLeaveDays: 10 })
        }}
      >
        Salvar
      </Button>
      {/* {<h1>Saved</h1>} */}
    </div>
  )
}
