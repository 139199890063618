import React from 'react'
import SceneObject from '../../SceneObject'

const Writings = React.memo(props => {
  return (
    <div>
      <SceneObject
        transform={{ position: { x: 260, y: 600 }, scale: { x: 4000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>We've always</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 280, y: 600 }, scale: { x: 4000, y: 100 } }}
      />
      <SceneObject
        transform={{ position: { x: 280, y: 550 }, scale: { x: 800, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>watched you</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 1740, y: 600 }, scale: { x: 1000, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>You have </h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 1570, y: 550 }, scale: { x: 800, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>mushroomed notably</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 3630, y: 600 }, scale: { x: 1300, y: 100 } }}
      >
        <h1 style={{ color: 'Yellow' }}>CONGRATULATIONS</h1>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 3700, y: 500 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>for the obstacles</h2>
      </SceneObject>
      <SceneObject
        transform={{ position: { x: 3875, y: 450 }, scale: { x: 1300, y: 100 } }}
      >
        <h2 style={{ color: 'Brown' }}>crossed!</h2>
      </SceneObject>
    </div>

  )
})

export default Writings
